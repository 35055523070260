import { useState } from "react";
import {
    FlexRow,
    FlexCell,
    LabeledInput,
    PickerInput,
    DropdownMenuBody,
    DropdownMenuButton,
    Dropdown,
    Badge,
    Tooltip,
    IconButton,
    BadgeProps,
} from "@epam/uui";
import { DropdownBodyProps, ILens, useArrayDataSource } from "@epam/uui-core";
import {
    IRiskAssessmentLevelData,
    IRiskAssessmentLevelStatus,
    IRiskDamageEventRiskAssessment,
} from "../../data/api";

import { ReactComponent as infoIcon } from "@epam/assets/icons/common/notification-info-outline-18.svg";
import { ReactComponent as navigationDownIcon } from "@epam/assets/icons/navigation-chevron_down-outline.svg";

import css from "./RiskAssessmentForm.module.scss";

export interface IRiskAssessmentFormProps {
    type: string;
    lens: ILens<IRiskDamageEventRiskAssessment>;
}
export const RiskAssessmentForm = (props: IRiskAssessmentFormProps) => {
    const { type, lens } = props;
    // const [selectedItem, setSelectedItem] =
    //     useState<IRiskAssessmentLevelStatus>();
    const riskLevelDataSource = useArrayDataSource(
        {
            items: [
                { id: 1, name: "Tief" },
                { id: 2, name: "Mittel" },
                { id: 3, name: "Hoch" },
            ],
        },
        [],
    );

    const statuses: IRiskAssessmentLevelStatus[] = [
        { id: 1, name: "Tief", priority: 1, color: "success" },
        { id: 2, name: "Mittel", priority: 2, color: "warning" },
        { id: 3, name: "Hoch", priority: 3, color: "critical" },
    ];

    const types: IRiskAssessmentLevelData[] = [
        { id: 1, name: "Tief" },
        { id: 2, name: "Mittel" },
        { id: 3, name: "Hoch" },
    ];

    const getStatus = (id: number) =>
        statuses.filter((item) => item.id === id)[0];

    const getType = (id: number) => types.filter((item) => item.id === id)[0];

    const calculateRiskLevel = (damageLevel: number, eventLevel: number): number => {
        // Ensure the inputs are valid integers within the range 1 to 3
        if (!Number.isInteger(damageLevel) || !Number.isInteger(eventLevel) || 
            damageLevel < 1 || damageLevel > 3 || eventLevel < 1 || eventLevel > 3) {
            return 0; // Return 0 for invalid inputs
        }
    
        const calcResult = damageLevel * eventLevel;
    
        // Determine the risk level based on the product
        if (calcResult >= 1 && calcResult <= 2) {
            return 1; // Low Risk
        } else if (calcResult >= 3 && calcResult <= 4) {
            return 2; // Medium Risk
        } else if (calcResult >= 6) {
            return 3; // High Risk
        }
    
        return 0; // Default to 0 if no conditions are met
    };
    

    const handleDropdown = (id: number) => {
        // setSelectedItem(getStatus(id));
    };

    const statusDot = (color: string) => (
        <span
            className={css.dot}
            style={{ backgroundColor: `var(--uui-${color}-50)` }}
        />
    );

    const renderDropdownBody = (
        props: DropdownBodyProps,
        lens: ILens<IRiskAssessmentLevelData>,
        type: string,
        selectedStatus?: IRiskAssessmentLevelStatus | null,
    ) => {
        return (
            <DropdownMenuBody {...props}>
                {statuses.map((item) => (
                    <DropdownMenuButton
                        key={item.id}
                        caption={item.name}
                        icon={() => statusDot(item.color)}
                        onClick={() => {
                            // handleDropdown(item.id);
                            if(type !== "Risiko")
                            {lens.set(getType(item.id));}
                            else 
                            {
                                const damageLevel = lens.get();
                                console.log(lens)
                            }
                            
                        }}
                        isSelected={
                            selectedStatus !== null
                                ? item.id === selectedStatus?.id
                                : false
                        }
                    />
                ))}
            </DropdownMenuBody>
        );
    };

    const renderAssessmentDropdown = (
        name: string,
        label: string,
        lens: ILens<IRiskAssessmentLevelData>,
        isReadOnly: boolean,
    ) => {
        const assessmentLevel = lens.get();
        const data =
            assessmentLevel && assessmentLevel.id !== undefined
                ? getStatus(assessmentLevel.id)
                : null;
        return (
            <FlexRow vPadding="12">
                <FlexCell minWidth={200} grow={1}>
                    <LabeledInput
                        htmlFor={name}
                        label={label}
                        {...lens.toProps()}
                    >
                        {/* <PickerInput
                            {...lens.toProps()}
                            dataSource={riskLevelDataSource}
                            selectionMode="single"
                            id={name}
                            getName={(item) => item?.name || ""}
                            sorting={{ field: "id", direction: "asc" }}
                            valueType="id"
                            placeholder={label}
                        /> */}

                        <Dropdown
                            renderBody={(props) =>
                                renderDropdownBody(props, lens, label, data)
                            }
                            renderTarget={(props) => (
                                <Badge
                                    {...props}
                                    dropdownIcon={
                                        !isReadOnly
                                            ? navigationDownIcon
                                            : undefined
                                    }
                                    color={data?.color as BadgeProps["color"]}
                                    fill="outline"
                                    caption={data?.name || label}
                                    size="30"
                                    indicator={true}
                                    isDisabled={isReadOnly}
                                />
                            )}
                            placement="bottom-start"
                            isNotUnfoldable={isReadOnly}
                        />
                        {/* <Tooltip content="Info tooltip" placement="top">
                            <IconButton
                                color="neutral"
                                icon={infoIcon}
                                cx={css.infoIcon}
                            />
                        </Tooltip> */}
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
        );
    };

    return (
        <>
            {renderAssessmentDropdown(
                `${type}DamageLevel`,
                "Schaden",
                lens.prop("damageLevel"),
                false,
            )}
            {renderAssessmentDropdown(
                `${type}EventLevel`,
                "Eintreten",
                lens.prop("eventLevel"),
                false,
            )}
            {renderAssessmentDropdown(
                `${type}RiskLevel`,
                "Risiko",
                lens.prop("riskLevel"),
                true,
            )}
        </>
    );
};

import { DataColumnProps } from "@epam/uui-core";
import {
    Badge,
    FlexRow,
    IconButton,
    IconContainer,
    Text,
    Tooltip,
} from "@epam/uui";

import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";
import { ReactComponent as EditIcon } from "@epam/assets/icons/content-edit-fill.svg";
import { ReactComponent as DeleteIcon } from "@epam/assets/icons/action-delete-outline.svg";

import { IDocumentData } from "../data/api";

import css from "./DocumentPage.module.scss";

export const getColumnConfiguration = (
    handleDeleteClick: (control: IDocumentData) => void,
): DataColumnProps<IDocumentData, number>[] => [
    {
        key: "number",
        caption: "Nr.",
        render: (item) => (
            <Text color="secondary" fontWeight="600">
                {item.number}
            </Text>
        ),
        width: 150,
        fix: "left",
        isSortable: true,
        isLocked: true,
    },
    {
        key: "category",
        caption: "Kategorie",
        render: (item) => <Text>{item.category}</Text>,
        width: 150,
        minWidth: 150,
        isSortable: true,
        isLocked: true,
        isAlwaysVisible: true,
    },
    {
        key: "name",
        caption: "Bezeichnung Dokument",
        render: (item) => <Text>{item.name}</Text>,
        width: 180,
        grow: 0,
        isLocked: false,
    },
    {
        key: "description",
        caption: "Beschreibung Dokument",
        render: (item) => <Text>{item.description}</Text>,
        width: 300,
        grow: 1,
        isLocked: false,
    },
    {
        key: "effectiveDate",
        caption: "Gültig ab..",
        render: (item) => <Text>{item.effectiveDate?.toDateString()}</Text>,
        width: 180,
        isLocked: false,
    },
    {
        key: "file",
        caption: "Datei/en",
        render: (item) => <Text>{item.file.name}</Text>,
        width: 250,
        isHiddenByDefault: false,
    },
    {
        key: "responsibleUser",
        caption: "Verantwortlich",
        render: (item) => <Text>{item.responsibleUser.abbreviation}</Text>,
        width: 150,
        isHiddenByDefault: false,
    },
    {
        key: "detailed",
        render: () => {
            return (
                <div className={css.detailedActions}>
                    {/* <IconButton
                        cx={css.detailedIcon}
                        icon={ViewIcon}
                        size="18"
                    /> */}
                    <IconButton
                        cx={css.detailedIcon}
                        icon={EditIcon}
                        size="18"
                    />
                    <IconButton
                        cx={css.detailedIcon}
                        icon={DeleteIcon}
                        onClick={handleDeleteClick}
                        size="18"
                    />
                </div>
            );
        },
        width: 54,
        alignSelf: "center",
        fix: "right",
        allowResizing: false,
    },
];

import { FlexRow, Text } from "@epam/uui";
import { IconContainer } from "@epam/uui-components";
import { SidebarDataItemConfig } from "src/common/SidebarDataPanel";

import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";

import { IControlData } from "../data/api";

export const getDataConfiguration: SidebarDataItemConfig<IControlData>[] = [
    {
        title: "Nr.",
        render: (item) => {
            return <Text>{item.number}</Text>;
        },
    },
    {
        title: "Risiko",
        render: (item) => {
            return <Text>{item.risk.number}</Text>;
        },
    },
    {
        title: "Schlüsselkontrolle",
        render: (item) => {
            return (
                <FlexRow>
                    <IconContainer
                        icon={IsImporantIcon}
                        style={{
                            fill: item.isImportant ? "#88CC00" : "#ccc",
                        }}
                    />
                </FlexRow>
            );
        },
    },
    {
        title: "Kurzbezeichnung",
        render: (item) => <Text>{item.name}</Text>,
    },
    {
        title: "Kontrollbeschreibung",
        render: (item) => <Text>{item.description}</Text>,
    },
    {
        title: "Art der Dokumentation",
        render: (item) => <Text>{item.documentationType}</Text>,
    },
    {
        title: "Periodizität",
        render: (item) => <Text>{item.periodicity.name}</Text>,
    },
    {
        title: "+Tage",
        render: (item) => <Text>{item.shiftDays}</Text>,
    },
    {
        title: "Verfall (Tage)",
        render: (item) => <Text>{item.dueDays}</Text>,
    },
    {
        title: "Dokumente",
        render: (item) =>
            item.documents?.map((doc, index) => (
                <Text key={index}>{doc.file.name}</Text>
            )),
    },
    {
        title: "Verantwortlich",
        render: (item) => <Text>{item.responsibleUser.abbreviation}</Text>,
    },
    {
        title: "Empfänger",
        render: (item) => (
            <>
                <Text>{item.assignedUser1.abbreviation}</Text>
                {item.assignedUser2 && (
                    <Text>{item.assignedUser2.abbreviation}</Text>
                )}
            </>
        ),
    },
];

import { Metadata } from "@epam/uui-core";
import { IRiskData } from "../../data/api";

export const RiskFormValidationSchema = (
    value: IRiskData,
): Metadata<IRiskData> => ({
    props: {
        number: { isRequired: true },
        grossAssessment: {
            props: {
                riskIndicatorText: { isRequired: true },
            },
        },
    },
});

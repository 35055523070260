import {
    ArrayDataSource,
    AsyncDataSource,
    TableFiltersConfig,
} from "@epam/uui-core";
import { IControlData } from "../data/api";
import { defaultPredicates, FlexRow, Switch } from "@epam/uui";

export const getFilterConfiguration = (
    data: IControlData[],
): TableFiltersConfig<IControlData>[] => {
    return [
        {
            field: "number",
            columnKey: "number",
            title: "Kontroll-Nr.",
            type: "multiPicker",
            dataSource: new ArrayDataSource({
                items: data.map((item) => ({
                    id: item.number,
                    name: item.number,
                })),
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: false,
        },
        {
            field: "risk",
            columnKey: "riskNnumber",
            title: "Riskiko-Nr.",
            type: "multiPicker",
            dataSource: new ArrayDataSource({
                items: data.map((item) => ({
                    id: item.risk.number,
                    name: item.risk.number,
                })),
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: false,
        },
        {
            field: "isImportant",
            columnKey: "isImportant",
            title: "Schlüsselkontrolle",
            type: "multiPicker", // or "radio" if you want to restrict to one value
            dataSource: new AsyncDataSource({
                api: () =>
                    Promise.resolve([
                        { id: 1, name: "Wichtig" },
                        { id: 2, name: "Unwichtig" },
                    ]), // You can set the options here as a static array
            }),
            showSearch: false, // Disable search if the list is small
            highlightSearchMatches: false,
            isAlwaysVisible: true,
        },
        {
            field: "periodicity",
            columnKey: "periodicity",
            title: "Periodizität",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () =>
                    Promise.resolve([
                        { id: 1, name: "Täglich" },
                        { id: 2, name: "Wöchentlich" },
                        { id: 3, name: "Monatlich" },
                        { id: 4, name: "Quartalsweise" },
                        { id: 5, name: "Halbjährlich" },
                        { id: 6, name: "Jährlich" },
                        { id: 7, name: "alle 2 Jahre" },
                        { id: 8, name: "alle 3 Jahre" },
                        { id: 9, name: "Trimesterweise" },
                    ]), // You can set the options here as a static array
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: true,
        },
        {
            field: "responsibleUser",
            columnKey: "responsibleUser",
            title: "Verantwortlicher",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () => {
                    let uniqueUsers = Array.from(
                        new Set(
                            data.map(
                                (item) => item.responsibleUser.abbreviation,
                            ),
                        ),
                    );
                    return Promise.resolve(
                        uniqueUsers.map((item) => ({
                            id: item,
                            name: item,
                        })),
                    );
                },
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: true,
        },
        {
            field: "assignedUser1",
            columnKey: "assignedUser1",
            title: "Empfänger",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () => {
                    let uniqueUsers = Array.from(
                        new Set(
                            data.map((item) => item.assignedUser1.abbreviation),
                        ),
                    );
                    return Promise.resolve(
                        uniqueUsers.map((item) => ({
                            id: item,
                            name: item,
                        })),
                    );
                },
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: true,
        },
    ];
};

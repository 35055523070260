import { IIksApiResponse } from "../baseTypes";
import { IControlData } from "./controlTypes";

export const controlDummyData: IIksApiResponse<IControlData[]> = {
    status: "Success",
    message: "Operation completed successfully",
    data: [
        {
            __typename: "Control",
            id: 1,
            number: "C1",
            name: "Kontrolle 1",
            isImportant: true,
            description: "Kontrolle",
            documentationType: "Type A",
            shiftDays: 5,
            dueDays: 10,
            risk: {
                id: 39,
                number: "R1",
                description:
                    "Unerwünschter oder unerlaubter Negativsaldo auf einem Kundenportfolio",
            },
            periodicity: {
                id: 1,
                name: "Täglich",
            },
            responsibleUser: {
                id: "027863d6-1c3a-446f-a16d-8c159d76a087",
                abbreviation: "GAM",
            },
            assignedUser1: {
                id: "19edb365-7cce-4b6b-a89e-9887e15644b7",
                abbreviation: "KAT",
            },
            assignedUser2: null,
            documents: [
                {
                    id: 81,
                    file: {
                        name: "WE Geschäftsverbindungen und Eigengeschäfte",
                        type: "PDF",
                    },
                },
                {
                    id: 82,
                    file: {
                        name: "Eigengeschäfte",
                        type: "DOCX",
                    },
                },
            ],
            isActive: true,
        },
        {
            __typename: "Control",
            id: 2,
            number: "C2",
            name: "Kontrolle 2",
            isImportant: false,
            description: "Sicherstellung von Datenintegrität",
            documentationType: "Type B",
            shiftDays: 3,
            dueDays: 7,
            risk: {
                id: 40,
                number: "R2",
                description: "Fehlende Datenvalidierung in Finanztransaktionen",
            },
            periodicity: {
                id: 2,
                name: "Wöchentlich",
            },
            responsibleUser: {
                id: "a7b3e89d-2c7a-4a50-b93b-12954fa9c121",
                abbreviation: "JSM",
            },
            assignedUser1: {
                id: "c2d9f8a5-1b47-4e9b-b589-84e3f62e6d92",
                abbreviation: "MKL",
            },
            assignedUser2: {
                id: "c2d9f8a5-1b47-4e9b-b589-84e3f6211d92",
                abbreviation: "TIM",
            },
            documents: [
                {
                    id: 81,
                    file: {
                        name: "WE Geschäftsverbindungen und Eigengeschäfte",
                        type: "PDF",
                    },
                },
                {
                    id: 82,
                    file: { name: "Eigengeschäfte", type: "DOCX" },
                },
            ],
            isActive: true,
        },
        {
            __typename: "Control",
            id: 3,
            number: "C3",
            name: "Kontrolle 3",
            isImportant: true,
            description: "Überprüfung der Benutzerzugriffsrechte",
            documentationType: "Type C",
            shiftDays: 2,
            dueDays: 5,
            risk: {
                id: 41,
                number: "R3",
                description:
                    "Unautorisierte Benutzerzugriffe auf sensible Datenbanken",
            },
            periodicity: {
                id: 3,
                name: "Monatlich",
            },
            responsibleUser: {
                id: "b9821f3d-4a6a-4e39-bef2-f153b10f5b99",
                abbreviation: "ALX",
            },
            assignedUser1: {
                id: "f83912c8-35a5-4d95-8441-5cda2b187e9d",
                abbreviation: "ZPT",
            },
            assignedUser2: null,
            isActive: true,
        },
        {
            __typename: "Control",
            id: 4,
            number: "C4",
            name: "Kontrolle 4",
            isImportant: false,
            description: "Audit-Protokollprüfung",
            documentationType: "Type D",
            shiftDays: 1,
            dueDays: 3,
            risk: {
                id: 42,
                number: "R4",
                description: "Manipulation von Systemprotokollen",
            },
            periodicity: {
                id: 1,
                name: "Täglich",
            },
            responsibleUser: {
                id: "c512d3f1-74f8-4f98-b34d-9bfcf674f8bb",
                abbreviation: "MRN",
            },
            assignedUser1: {
                id: "e26b7891-7c4f-4e6c-b812-1f39b4dfd3a8",
                abbreviation: "LNK",
            },
            assignedUser2: null,
            isActive: false,
        },
    ],
};

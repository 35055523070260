import * as React from "react";
import { useEffect } from "react";
import { cx, IHasChildren, useUuiContext } from "@epam/uui-core";
import { ErrorHandler } from "@epam/promo";

import { DataTablePageContent } from "./PageContent";

import css from "./Page.module.scss";

export interface PageProps extends IHasChildren {
    renderHeader?: () => React.ReactNode;
    renderFooter?: () => React.ReactNode;
    rootCx?: string;
    contentCx?: string;
    showHeaderPanel?: boolean;
    contentTitle?: string;
    contentAddClickHandler?: () => void;
    renderContentSidebarPanel?: () => React.ReactNode;
    isFullScreen?: boolean;
    isProtectedPage?: boolean;
    wrapperRef?: React.Ref<HTMLElement>;
    onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

export function Page(props: PageProps) {
    const {
        renderHeader,
        renderFooter,
        rootCx,
        contentCx,
        showHeaderPanel,
        contentTitle,
        contentAddClickHandler,
        renderContentSidebarPanel,
        children,
        isFullScreen,
        isProtectedPage,
    } = props;

    const services = useUuiContext();
    // const userContext = useAppUser();
    const getPageName = (pageId: string) => {
        if (!pageId) return "";

        const capitalizedPageId =
            pageId.charAt(0).toUpperCase() + pageId.slice(1);
        return capitalizedPageId.replace(/([a-z])([A-Z])/g, "$1 $2");
    };

    useEffect(() => {
        const currentLink = services.uuiRouter.getCurrentLink();
        const pageId = currentLink.query.id;
        const pageName = getPageName(pageId);
        // const isLogin = !userContext?.user?.token;
        // setIsLoginPage(isLogin);

        document.title = pageName
            ? `${pageName} | IKSUI`
            : "IKS Online - Risk Management Suite";
    }, [services.uuiRouter, services.uuiRouter.getCurrentLink().search]);

    return (
        <div
            className={cx(css.root, rootCx, {
                [`${css.rootNonUser}`]: !isProtectedPage,
            })}
        >
            <header>{!isFullScreen && renderHeader?.()}</header>
            <ErrorHandler cx={css.errorBlock}>
                <main
                    className={cx(css.content, contentCx)}
                    ref={props.wrapperRef}
                    onClick={props.onClick}
                >
                    {showHeaderPanel ? (
                        <DataTablePageContent
                            title={contentTitle || ""}
                            renderSidebarPanel={renderContentSidebarPanel}
                            addClickHandler={contentAddClickHandler}
                        >
                            {children}
                        </DataTablePageContent>
                    ) : (
                        <>{children}</>
                    )}
                </main>
                <footer>{!isFullScreen && renderFooter?.()}</footer>
            </ErrorHandler>
        </div>
    );
}

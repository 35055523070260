import React from "react";
import { IHasChildren } from "@epam/uui-core";
import { IconButton } from "@epam/uui";

import { ReactComponent as AddIcon } from "@epam/assets/icons/action-add-outline.svg";

import css from "./DataTablePageContent.module.scss";

export interface IDataTablePageContentProps extends IHasChildren {
    title: string;
    addClickHandler?: () => void;
    renderSidebarPanel?: () => React.ReactNode;
}
export function DataTablePageContent({
    title,
    addClickHandler,
    renderSidebarPanel,
    children,
}: IDataTablePageContentProps) {
    return (
        <>
            <div className={css.contentWrapper}>
                <div className={css.contentContainer}>
                    <div className={css.headerPanel}>
                        <div className={css.headerPanelWrapper}>
                            <div className={css.header}>
                                <div className={css.headerText}>{title}</div>
                                <div className={css.addButtonWrap}>
                                    <IconButton
                                        icon={AddIcon}
                                        cx={css.addButton}
                                        color={"white"}
                                        onClick={() => {
                                            addClickHandler?.();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
                {renderSidebarPanel?.()}
            </div>
        </>
    );
}

import { ILens } from "@epam/uui-core";
import { FlexCell, FlexRow, LabeledInput, TextArea } from "@epam/uui";
import { IRiskGrossAssessmentData } from "../../data/api";
import { RiskAssessmentForm } from "./RiskAssessmentForm";

export interface RiskGrossAssessmentFormProps {
    lens: ILens<IRiskGrossAssessmentData>;
}
export const RiskGrossAssessmentForm = (
    props: RiskGrossAssessmentFormProps,
) => {
    const { lens } = props;

    return (
        <>
            {<RiskAssessmentForm lens={lens} type="grossAssessment" />}
            <FlexRow>
                <FlexCell grow={1}>
                    <LabeledInput
                        htmlFor="riskIndicatorText"
                        label="Risikoindikator"
                        {...lens.prop("riskIndicatorText").toProps()}
                    >
                        <TextArea
                            {...lens.prop("riskIndicatorText").toProps()}
                            placeholder=""
                            rows={6}
                            id="riskIndicatorText"
                        />
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
            <FlexRow>
                <FlexCell grow={1}>
                    <LabeledInput
                        htmlFor="existingMeasures"
                        label="Risikomindernde Massnahmen"
                        {...lens.prop("existingMeasures").toProps()}
                    >
                        <TextArea
                            {...lens.prop("existingMeasures").toProps()}
                            placeholder=""
                            rows={6}
                            id="existingMeasures"
                        />
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
        </>
    );
};

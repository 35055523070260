import { FlexRow, Text, Panel } from "@epam/uui";
import { CompanySection } from "./CompanySection";

import css from "./LoginSection.module.scss";

export interface CompanyInfoProps {}

export const CompanyInfo = (props: CompanyInfoProps) => {
    const showPartnerBlock = process.env.REACT_APP_SHOW_PARTNER_BLOCK === "1";

    return (
        <div className={css.companyInfo}>
            <Panel>
                <FlexRow>
                    <Text cx={css.text}>
                        Mit <b>IKS online</b> haben wir das Interne
                        Kontrollsystem (IKS) komplett digitalisiert und
                        automatisiert. Wir haben Wert darauf gelegt, dass Sie
                        Ihre Routine mit tabellarischen Darstellungen, bspw.
                        Excel, beibehalten können. Unsere nach
                        bankengesetzlichen Vorschriften zertifizierte Lösung
                        erlaubt die Erfassung, Prüfung, Kontrolle und das
                        Reporting voll automatisiert, jederzeit und
                        ortsunabhängig. Mit wenigen Klicks.
                    </Text>
                </FlexRow>
                <FlexRow>
                    <Text cx={css.text}>
                        Kontaktieren Sie uns und wir präsentieren Ihnen die voll
                        lauffähige und bereits im Einsatz befindliche Lösung
                        persönlich.
                    </Text>
                </FlexRow>
                <div className={css.addressBlock}>
                    <FlexRow>
                        <CompanySection
                            name="Law Tech AG"
                            addressLine1="Kesslerstrasse 1"
                            addressLine2="9000 St. Gallen"
                            phone="+41 71 220 33 10"
                            email="kontakt@law-teq.ch"
                        />
                        {showPartnerBlock && (
                            <CompanySection
                                name="Equilas AG"
                                addressLine1="Genfergasse 8"
                                addressLine2="3011 Bern"
                                phone="+41 58 748 44 00"
                                email="info@equilas.ch"
                            />
                        )}
                    </FlexRow>
                </div>
            </Panel>
        </div>
    );
};

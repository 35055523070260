import React, { useEffect, useState } from "react";
import {
    FlexCell,
    FlexRow,
    Text,
    Panel,
    Button,
    TextInput,
    Switch,
    useForm,
    Blocker,
} from "@epam/uui";
import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { TApi } from "../data/apiDefinition";
import { IIksAuthenticateRequest } from "../data/api";
import { useAppUser } from "../helpers/appUser";
import { useNotifications } from "../hooks";
import { LoginValidationSchema } from "./LoginValidationSchema";
import { CompanyInfo } from "./CompanyInfo";

import css from "./LoginSection.module.scss";

export function LoginSection() {
    const ERROR_MESSAGES = {
        LOGIN_ERROR: "Die Login-Daten sind nicht korrekt.",
    };

    const svc = useUuiContext<TApi, UuiContexts>();
    const userContext = useAppUser();

    const { showError } = useNotifications();
    const [isLoading, setIsLoading] = useState(false);
    const [switchRememberMeValue, setSwitchRememberMeValue] = useState(false);
    useEffect(() => {
        if (userContext?.hasUser()) {
            svc.uuiRouter.redirect("/dashboard");
        }
    });

    const demoUser = process.env.REACT_APP_DEMO_USER;
    const demoUserPass = process.env.REACT_APP_DEMO_USERPASSWORD;

    const { lens, save } = useForm<IIksAuthenticateRequest>({
        value: { email: demoUser, password: demoUserPass },
        getMetadata: LoginValidationSchema,
        onSave: async (formValue) => {
            setIsLoading(true);
            svc.api.auth
                .authenticateUser(formValue)
                .then((response) => {
                    userContext?.addUser(response.data);
                    svc.uuiRouter.redirect({ pathname: "/dashboard" });
                })
                .catch((error) => {
                    console.error("Cannot login the user", error);
                    setIsLoading(false);
                    showError(ERROR_MESSAGES.LOGIN_ERROR);
                });
        },
        onError: () => {
            setIsLoading(false);
            showError(ERROR_MESSAGES.LOGIN_ERROR);
        },
        beforeLeave: (): Promise<boolean> => {
            return Promise.resolve(false);
        },
    });

    return (
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.wrapper}>
                    <Blocker isEnabled={isLoading} />
                    <Panel
                        shadow={false}
                        cx={css.componentsMiddleWrapper}
                        style={{ padding: "10px" }}
                    >
                        <FlexRow columnGap="12" cx={css.middleRow}>
                            <FlexCell grow={0.2}>
                                <TextInput
                                    cx={css.middleRowText}
                                    size="30"
                                    placeholder="E-Mail"
                                    isDisabled={isLoading}
                                    {...lens.prop("email").toProps()}
                                />
                            </FlexCell>
                            <FlexCell grow={0.2}>
                                <TextInput
                                    cx={css.middleRowText}
                                    size="30"
                                    type="password"
                                    placeholder="Passwort"
                                    isDisabled={isLoading}
                                    {...lens.prop("password").toProps()}
                                />
                            </FlexCell>
                            <FlexCell>
                                <Button
                                    fill="outline"
                                    caption="Login"
                                    size="30"
                                    onClick={save}
                                    isDisabled={isLoading}
                                />
                            </FlexCell>
                        </FlexRow>
                        <FlexRow>
                            <FlexCell grow={0.3}>
                                <Switch
                                    label="Dieses Login wiedererkennen?"
                                    value={switchRememberMeValue}
                                    onValueChange={setSwitchRememberMeValue}
                                />
                            </FlexCell>
                            <FlexCell grow={0.2}>
                                <Text>Passwort vergessen?</Text>
                            </FlexCell>
                        </FlexRow>
                    </Panel>
                    <CompanyInfo />
                </div>
            </div>
        </div>
    );
}

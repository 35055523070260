import { Metadata } from "@epam/uui-core";
import { IIksAuthenticateRequest } from "../data/api";

const ERROR_MESSAGES = {
    EMAIL_NOT_VALID: "Nicht gültige E-Mail",
    EMAIL_IS_REQUIRED: "Die Nutzer E-Mail fehlt.",
    PASSWORD_IS_REQUIRED: "Das Passwort muss eingegeben werden.",
};

export const LoginValidationSchema = (
    value: IIksAuthenticateRequest,
): Metadata<IIksAuthenticateRequest> => ({
    props: {
        email: {
            validators: [
                (value, user) => [!value && ERROR_MESSAGES.EMAIL_IS_REQUIRED],
                (value, user) => [
                    !(value && value.includes("@")) &&
                        ERROR_MESSAGES.EMAIL_NOT_VALID,
                ],
            ],
        },
        password: {
            validators: [
                (value, user) => [
                    !value && ERROR_MESSAGES.PASSWORD_IS_REQUIRED,
                ],
            ],
        },
    },
});

import { DataSourceState, TableFiltersConfig } from "@epam/uui-core";
import { IDocumentData } from "../data/api";

export const applyFilters = (
    data: IDocumentData[],
    filtersConfig: TableFiltersConfig<IDocumentData>[],
    state?: DataSourceState,
): IDocumentData[] => {
    let filteredData = data;

    Object.keys(state?.filter || {}).forEach((filterField) => {
        const filterValue = state?.filter?.[filterField];

        switch (filterField) {
            case "isActive":
                filteredData = filteredData.filter((item) => {
                    if (filterValue === true) {
                        return item.isActive === true;
                    } else if (filterValue === false) {
                        return item.isActive === false;
                    }
                    return true;
                });
                break;

            case "category":
                // "in" or "nin" filter for number
                if (filterValue?.in) {
                    filteredData = filteredData.filter((item) =>
                        filterValue.in.includes(item.category),
                    );
                } else if (filterValue?.nin) {
                    filteredData = filteredData.filter(
                        (item) => !filterValue.nin.includes(item.category),
                    );
                }
                break;

            default:
                break;
        }
    });

    return filteredData;
};

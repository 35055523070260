import {
    ArrayDataSource,
    AsyncDataSource,
    TableFiltersConfig,
} from "@epam/uui-core";
import { IRiskData } from "../data/api";
import { defaultPredicates, FlexRow, Switch } from "@epam/uui";

export const getFilterConfiguration = (
    data: IRiskData[],
): TableFiltersConfig<IRiskData>[] => {
    return [
        {
            field: "isActive",
            columnKey: "isActive",
            title: "Active?",
            type: "custom",
            getTogglerValue: (props) => (props.value ? "All" : "No"),
            render: (props) => {
                return (
                    <FlexRow vPadding="12" padding="12">
                        <Switch
                            label="Show only active"
                            value={props.value}
                            onValueChange={props.onValueChange}
                        />
                    </FlexRow>
                );
            },
            isAlwaysVisible: true,
        },
        {
            field: "number",
            columnKey: "number",
            title: "Risiko-Nr.",
            type: "multiPicker",
            dataSource: new ArrayDataSource({
                items: data.map((item) => ({
                    id: item.number,
                    name: item.number,
                })),
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: true,
        },
        {
            field: "type",
            columnKey: "type",
            title: "Risikoart",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () => {
                    let uniqueRiskTypes = Array.from(
                        new Set(data.map((item) => item.type)),
                    );
                    return Promise.resolve(
                        uniqueRiskTypes.map((item) => ({
                            id: item,
                            name: item,
                        })),
                    );
                },
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: true,
        },
        {
            field: "isImportant",
            columnKey: "isImportant",
            title: "Schlüsselrisiko",
            type: "multiPicker", // or "radio" if you want to restrict to one value
            dataSource: new AsyncDataSource({
                api: () =>
                    Promise.resolve([
                        { id: 1, name: "Wichtig" },
                        { id: 2, name: "Unwichtig" },
                    ]), // You can set the options here as a static array
            }),
            showSearch: false, // Disable search if the list is small
            highlightSearchMatches: false,
            isAlwaysVisible: true,
        },
        {
            field: "grossAssessment",
            columnKey: "grossRiskLevel",
            title: "Brutto-Risiko",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () =>
                    Promise.resolve([
                        { id: 1, name: "Tief" },
                        { id: 2, name: "Mittel" },
                        { id: 3, name: "Hoch" },
                    ]), // You can set the options here as a static array
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: false,
        },
        {
            field: "netAssessment",
            columnKey: "netRiskLevel",
            title: "Netto-Risiko",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () =>
                    Promise.resolve([
                        { id: 1, name: "Tief" },
                        { id: 2, name: "Mittel" },
                        { id: 3, name: "Hoch" },
                    ]), // You can set the options here as a static array
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: false,
        },
        {
            field: "controls",
            columnKey: "netRiskLevel",
            title: "Kontrollperiodizität",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () =>
                    Promise.resolve([
                        { id: 1, name: "Täglich" },
                        { id: 2, name: "Wöchentlich" },
                        { id: 3, name: "Monatlich" },
                        { id: 4, name: "Quartalsweise" },
                        { id: 5, name: "Halbjährlich" },
                        { id: 6, name: "Jährlich" },
                        { id: 7, name: "alle 2 Jahre" },
                        { id: 8, name: "alle 3 Jahre" },
                        { id: 9, name: "Trimesterweise" },
                    ]), // You can set the options here as a static array
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: false,
        },
    ];
};

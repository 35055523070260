import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { TApi } from "../data/apiDefinition";
import { IUserData, userDummyData } from "../data/api";

export const useUserData = () => {
    const svc = useUuiContext<TApi, UuiContexts>();

    const fetchData = async (token: string) => {
        if (!token) return [];
        try {
            const response = await svc.api.users.getUsers({ token });
            return response?.data || [];
        } catch (error) {
            console.error("Error fetching user data:", error);
            return [];
        }
    };

    const fetchDummyData = async (): Promise<IUserData[]> => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(userDummyData.data), 100);
        });
    };

    return { fetchData, fetchDummyData };
};

import { IProcessRequest } from "@epam/uui-core";
import { IDocumentData, IDocumentRequest } from "./documentTypes";
import { IIksApiResponse } from "../baseTypes";

export function getDocumentsApi(
    processRequest: IProcessRequest,
    origin: string,
) {
    return {
        getRegulatoryDocuments(req: IDocumentRequest) {
            const { token } = req;
            return processRequest<IIksApiResponse<IDocumentData[]>>(
                `${origin}/api/v1/documents`,
                "GET",
                null,
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
            );
        },
    };
}

import { Badge, FlexRow, Text } from "@epam/uui";
import { IconContainer } from "@epam/uui-components";
import { SidebarDataItemConfig } from "src/common/SidebarDataPanel";

import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";

import { IUserData } from "../data/api";

export const getDataConfiguration: SidebarDataItemConfig<IUserData>[] = [
    {
        title: "Name und Vorname",
        render: (item) => {
            return <Text>{item.fullName}</Text>;
        },
    },
    {
        title: "Signatur",
        render: (item) => {
            return <Text>{item.abbreviation}</Text>;
        },
    },
    {
        title: "Funktion",
        render: (item) => {
            return <Text>{item.functionName}</Text>;
        },
    },
    {
        title: "Abteilung",
        render: (item) => {
            return <Text>{item.departmentName}</Text>;
        },
    },
    {
        title: "Bereich",
        render: (item) => {
            return <Text>{item.sectorName}</Text>;
        },
    },
    {
        title: "E-Mail",
        render: (item) => {
            return <Text>{item.email}</Text>;
        },
    },
];

import { NotificationParams, useUuiContext, UuiContexts } from "@epam/uui-core";
import {
    ErrorNotification,
    FlexRow,
    SuccessNotification,
    Text,
    WarningNotification,
} from "@epam/uui";
import { TApi } from "../data/apiDefinition";

export const useNotifications = () => {
    const svc = useUuiContext<TApi, UuiContexts>();

    const showSuccess = (message: string, params?: NotificationParams) => {
        svc.uuiNotifications
            .show(
                (props) => (
                    <SuccessNotification {...props}>
                        <FlexRow alignItems="center">
                            <Text>{message}</Text>
                        </FlexRow>
                    </SuccessNotification>
                ),
                { ...params },
            )
            .then(() => {
                // Suppress
            })
            .catch((error) =>
                console.error("Success notification error: ", error),
            );
    };

    const showWarning = (message: string, params?: NotificationParams) => {
        svc.uuiNotifications
            .show(
                (props) => (
                    <WarningNotification {...props}>
                        <FlexRow alignItems="center">
                            <Text>{message}</Text>
                        </FlexRow>
                    </WarningNotification>
                ),
                { ...params },
            )
            .then(() => {
                // Suppress
            })
            .catch((error) =>
                console.error("Warning notification error: ", error),
            );
    };

    const showError = (message: string, params?: NotificationParams) => {
        svc.uuiNotifications
            .show(
                (props) => (
                    <ErrorNotification {...props}>
                        <FlexRow alignItems="center">
                            <Text>{message}</Text>
                        </FlexRow>
                    </ErrorNotification>
                ),
                { ...params },
            )
            .then(() => {
                // Suppress
            })
            .catch((error) =>
                console.error("Error notification error: ", error),
            );
    };

    return { showSuccess, showWarning, showError };
};

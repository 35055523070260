import { DataColumnProps } from "@epam/uui-core";
import { FlexRow, IconButton, IconContainer, Text, Tooltip } from "@epam/uui";

import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";
import { ReactComponent as EditIcon } from "@epam/assets/icons/content-edit-fill.svg";
import { ReactComponent as DeleteIcon } from "@epam/assets/icons/action-delete-outline.svg";

import { IControlData } from "../data/api/controls";

import css from "./ControlPage.module.scss";

export const getColumnConfiguration = (
    handleDeleteClick: (control: IControlData) => void,
): DataColumnProps<IControlData, number>[] => [
    {
        key: "number",
        caption: "Nr.",
        render: (item) => (
            <Text color="secondary" fontWeight="600">
                {item.number}
            </Text>
        ),
        width: 100,
        fix: "left",
        isSortable: true,
        isLocked: true,
    },
    {
        key: "riskNumber",
        caption: "Risiko",
        render: (item) => (
            <Tooltip
                color="neutral"
                placement="top-end"
                content={item.risk.description}
            >
                <Text>{item.risk.number}</Text>
            </Tooltip>
        ),
        width: 120,
        minWidth: 120,
        isSortable: true,
        isLocked: true,
        isAlwaysVisible: true,
    },
    {
        key: "isImportant",
        caption: (
            <IconContainer icon={IsImporantIcon} style={{ fill: "#88CC00" }} />
        ),
        render: (item) => {
            return (
                <FlexRow>
                    <IconContainer
                        icon={IsImporantIcon}
                        style={{
                            fill: item.isImportant ? "#88CC00" : "#ccc",
                        }}
                    />
                </FlexRow>
            );
        },
        renderTooltip: (risk) => {
            return (
                <Tooltip content="Is Key Control?" color="inverted">
                    Is Key Control?
                </Tooltip>
            );
        },
        width: 60,
        isSortable: true,
        isLocked: true,
        isAlwaysVisible: true,
    },
    {
        key: "name",
        caption: "Kurzbezeichnung",
        render: (item) => <Text>{item.name}</Text>,
        width: 240,
        grow: 0,
        isLocked: true,
    },
    {
        key: "description",
        caption: "Kontrollbeschreibung",
        render: (item) => <Text>{item.description}</Text>,
        width: 240,
        grow: 0,
        isLocked: true,
    },
    {
        key: "documentationType",
        caption: "Art der Dokumentation",
        render: (item) => <Text>{item.documentationType}</Text>,
        width: 150,
        isHiddenByDefault: false,
    },
    {
        key: "periodicity",
        caption: "Periodizität",
        render: (item) => <Text>{item.periodicity.name}</Text>,
        width: 120,
        isHiddenByDefault: false,
    },
    {
        key: "shiftDays",
        caption: "+Tage",
        render: (item) => <Text>{item.shiftDays}</Text>,
        width: 80,
        isHiddenByDefault: false,
    },
    {
        key: "dueDays",
        caption: "Verfall (Tage)",
        render: (item) => <Text>{item.dueDays}</Text>,
        width: 120,
        isHiddenByDefault: false,
    },
    {
        key: "detailed",
        render: () => {
            return (
                <div className={css.detailedActions}>
                    <IconButton
                        cx={css.detailedIcon}
                        icon={EditIcon}
                        size="18"
                    />
                    <IconButton
                        cx={css.detailedIcon}
                        icon={DeleteIcon}
                        onClick={handleDeleteClick}
                        size="18"
                    />
                </div>
            );
        },
        width: 54,
        alignSelf: "center",
        fix: "right",
        allowResizing: false,
    },
];

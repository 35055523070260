import { IProcessRequest } from "@epam/uui-core";
import { IUserData, IUserRequest } from "./userTypes";
import { IIksApiResponse } from "../baseTypes";

export function getUsersApi(processRequest: IProcessRequest, origin: string) {
    return {
        getUsers(req: IUserRequest) {
            const { token } = req;
            return processRequest<IIksApiResponse<IUserData[]>>(
                `${origin}/api/v1/users`,
                "GET",
                null,
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
            );
        },
    };
}

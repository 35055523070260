import { IIksApiResponse } from "../baseTypes";
import { IDocumentData } from "./documentTypes";

export const documentDummyData: IIksApiResponse<IDocumentData[]> = {
    status: "Success",
    message: "Operation completed successfully",
    data: [
        {
            __typename: "Document",
            id: 1,
            number: "DOC-001",
            category: "Regulatory Compliance",
            name: "General Data Protection Regulation (GDPR)",
            description:
                "Compliance guidelines for data protection and privacy.",
            effectiveDate: new Date("2023-01-15"),
            responsibleUser: { id: "U1", abbreviation: "JSM" },
            isActive: true,
            file: { name: "GDPR_Compliance.pdf", type: "PDF" },
        },
        {
            __typename: "Document",
            id: 2,
            number: "DOC-002",
            category: "Financial Regulations",
            name: "Basel III Framework",
            description:
                "Guidelines for capital and liquidity risk management.",
            effectiveDate: new Date("2022-12-10"),
            responsibleUser: { id: "U2", abbreviation: "ALB" },
            isActive: true,
            file: { name: "BaselIII_Framework.docx", type: "DOCX" },
        },
        {
            __typename: "Document",
            id: 3,
            number: "DOC-003",
            category: "AML/KYC Compliance",
            name: "Anti-Money Laundering (AML) Policy",
            description:
                "Guidelines for preventing money laundering activities.",
            effectiveDate: new Date("2021-08-05"),
            responsibleUser: { id: "U3", abbreviation: "RKT" },
            isActive: true,
            file: { name: "AML_Policy.doc", type: "DOC" },
        },
        {
            __typename: "Document",
            id: 4,
            number: "DOC-004",
            category: "Risk Management",
            name: "Operational Risk Framework",
            description: "Procedures for managing operational risk.",
            effectiveDate: new Date("2023-03-20"),
            responsibleUser: { id: "U4", abbreviation: "DMP" },
            isActive: true,
            file: { name: "Operational_Risk.pdf", type: "PDF" },
        },
        {
            __typename: "Document",
            id: 5,
            number: "DOC-005",
            category: "Regulatory Reporting",
            name: "IFRS 9 Implementation Guide",
            description: "Standards for financial reporting under IFRS 9.",
            effectiveDate: new Date("2022-07-18"),
            responsibleUser: { id: "U5", abbreviation: "LNR" },
            isActive: true,
            file: { name: "IFRS9_Guide.docx", type: "DOCX" },
        },
        {
            __typename: "Document",
            id: 6,
            number: "DOC-006",
            category: "Cybersecurity Compliance",
            name: "NIST Cybersecurity Framework",
            description: "Guidelines for improving cybersecurity resilience.",
            effectiveDate: new Date("2023-06-30"),
            responsibleUser: { id: "U1", abbreviation: "JSM" },
            isActive: true,
            file: { name: "NIST_Cybersecurity.pdf", type: "PDF" },
        },
        {
            __typename: "Document",
            id: 7,
            number: "DOC-007",
            category: "Internal Audit",
            name: "Internal Control Procedures",
            description: "Policies for internal audit and compliance.",
            effectiveDate: new Date("2021-11-01"),
            responsibleUser: { id: "U2", abbreviation: "ALB" },
            isActive: false,
            file: { name: "Internal_Audit.doc", type: "DOC" },
        },
        {
            __typename: "Document",
            id: 8,
            number: "DOC-008",
            category: "Regulatory Compliance",
            name: "ISO 27001 Security Policy",
            description: "Guidelines for information security management.",
            effectiveDate: new Date("2020-05-15"),
            responsibleUser: { id: "U3", abbreviation: "RKT" },
            isActive: false,
            file: { name: "ISO27001_Security.docx", type: "DOCX" },
        },
        {
            __typename: "Document",
            id: 9,
            number: "DOC-009",
            category: "Legal & Compliance",
            name: "Whistleblower Protection Policy",
            description:
                "Procedures for whistleblowing and anonymity protection.",
            effectiveDate: new Date("2022-02-28"),
            responsibleUser: { id: "U4", abbreviation: "DMP" },
            isActive: false,
            file: { name: "Whistleblower_Policy.pdf", type: "PDF" },
        },
        {
            __typename: "Document",
            id: 10,
            number: "DOC-010",
            category: "Regulatory Reporting",
            name: "Financial Crimes Compliance Guide",
            description:
                "Guidance on compliance with financial crime regulations.",
            effectiveDate: new Date("2019-09-10"),
            responsibleUser: { id: "U5", abbreviation: "LNR" },
            isActive: false,
            file: { name: "Financial_Crimes_Guide.doc", type: "DOC" },
        },
    ],
};

import {
    ArrayDataSource,
    AsyncDataSource,
    TableFiltersConfig,
} from "@epam/uui-core";
import { defaultPredicates, FlexRow, Switch } from "@epam/uui";

import { IUserData } from "../data/api";

export const getFilterConfiguration = (
    data: IUserData[],
): TableFiltersConfig<IUserData>[] => {
    return [
        {
            field: "fullName",
            columnKey: "fullName",
            title: "Name und Vorname",
            type: "multiPicker",
            dataSource: new ArrayDataSource({
                items: data.map((item) => ({
                    id: item.fullName,
                    name: item.fullName,
                })),
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: true,
        },
    ];
};

import { ILens } from "@epam/uui-core";
import { IRiskData } from "../../data/api";
import {
    FlexCell,
    FlexRow,
    LabeledInput,
    Switch,
    TextArea,
    TextInput,
} from "@epam/uui";

import css from "./RiskForm.module.scss";

export interface IRiskDetailsFormProps {
    lens: ILens<IRiskData>;
}
export const RiskDetailsForm = (props: IRiskDetailsFormProps) => {
    const { lens } = props;
    return (
        <>
            <FlexRow vPadding="12" cx={css.sectionRow}>
                <FlexCell minWidth={200} grow={1}>
                    <LabeledInput
                        htmlFor="number"
                        label="Nr."
                        {...lens.prop("number").toProps()}
                    >
                        <TextInput
                            {...lens.prop("number").toProps()}
                            placeholder=""
                            id="number"
                        />
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
            <FlexRow>
                <FlexCell grow={1}>
                    <LabeledInput
                        htmlFor="type"
                        label="Risikoart"
                        {...lens.prop("type").toProps()}
                    >
                        <TextInput
                            {...lens.prop("type").toProps()}
                            placeholder=""
                            id="type"
                        />
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
            <FlexRow>
                <FlexCell>
                    <Switch
                        label="Schlüsselrisiko"
                        {...lens.prop("isImportant").toProps()}
                    />
                </FlexCell>
            </FlexRow>
            <FlexRow>
                <FlexCell grow={1}>
                    <LabeledInput
                        htmlFor="description"
                        label="Beschreibung Risiko"
                        {...lens.prop("description").toProps()}
                    >
                        <TextArea
                            {...lens.prop("description").toProps()}
                            placeholder=""
                            rows={6}
                            id="description"
                        />
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
            <FlexRow>
                <FlexCell grow={1}>
                    <LabeledInput
                        htmlFor="iksObjective"
                        label="IKS-Ziele"
                        {...lens.prop("iksObjective").toProps()}
                    >
                        <TextArea
                            {...lens.prop("iksObjective").toProps()}
                            placeholder=""
                            rows={6}
                            id="iksObjective"
                        />
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
        </>
    );
};

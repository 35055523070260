import { FlexCell } from "@epam/uui";

import css from "./LoginSection.module.scss";

export interface CompanySectionProps {
    name: string;
    addressLine1: string;
    addressLine2: string;
    phone: string;
    email: string;
}
export const CompanySection = (props: CompanySectionProps) => {
    const { name, addressLine1, addressLine2, phone, email } = props;
    return (
        <FlexCell grow={0.4}>
            <div className={css.text}>
                <b>{name}</b>
            </div>
            <div className={css.text}>{addressLine1}</div>
            <div className={css.text}>{addressLine2}</div>
            <br />
            <div className={css.text}>{phone}</div>
            <div className={css.text}>{email}</div>
        </FlexCell>
    );
};

import { DataColumnProps } from "@epam/uui-core";
import {
    Badge,
    FlexRow,
    IconButton,
    IconContainer,
    Text,
    Tooltip,
} from "@epam/uui";

import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";
import { ReactComponent as EditIcon } from "@epam/assets/icons/content-edit-fill.svg";
import { ReactComponent as DeleteIcon } from "@epam/assets/icons/action-delete-outline.svg";

import { IUserData } from "../data/api";

import css from "./UserPage.module.scss";

export const getColumnConfiguration = (
    handleDeleteClick: (control: IUserData) => void,
): DataColumnProps<IUserData, number>[] => [
    {
        key: "fullName",
        caption: "Name und Vorname",
        render: (item) => (
            <Text color="secondary" fontWeight="600">
                {item.fullName}
            </Text>
        ),
        width: 300,
        fix: "left",
        isSortable: true,
        isLocked: true,
    },
    {
        key: "abbreviation",
        caption: "Signatur",
        render: (item) => <Text>{item.abbreviation}</Text>,
        width: 120,
        minWidth: 120,
        isSortable: true,
        isLocked: true,
        isAlwaysVisible: true,
    },
    {
        key: "functionName",
        caption: "Funktion",
        render: (item) => <Text>{item.functionName}</Text>,
        width: 200,
        grow: 0,
        isLocked: false,
    },
    {
        key: "departmentName",
        caption: "Abteilung",
        render: (item) => <Text>{item.departmentName}</Text>,
        width: 200,
        isLocked: false,
    },
    {
        key: "sectorName",
        caption: "Bereich",
        render: (item) => <Text>{item.sectorName}</Text>,
        width: 200,
        isLocked: false,
    },
    {
        key: "email",
        caption: "E-Mail",
        render: (item) => <Text>{item.email}</Text>,
        width: 250,
        isHiddenByDefault: false,
    },
    {
        key: "detailed",
        render: () => {
            return (
                <div className={css.detailedActions}>
                    {/* <IconButton
                        cx={css.detailedIcon}
                        icon={ViewIcon}
                        size="18"
                    /> */}
                    <IconButton
                        cx={css.detailedIcon}
                        icon={EditIcon}
                        size="18"
                    />
                    <IconButton
                        cx={css.detailedIcon}
                        icon={DeleteIcon}
                        onClick={handleDeleteClick}
                        size="18"
                    />
                </div>
            );
        },
        width: 54,
        alignSelf: "center",
        fix: "right",
        allowResizing: false,
    },
];

import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { TApi } from "../data/apiDefinition";
import { IControlData, controlDummyData } from "../data/api";

export const useControlData = () => {
    const svc = useUuiContext<TApi, UuiContexts>();

    const fetchData = async (token: string) => {
        if (!token) return [];
        try {
            const response = await svc.api.controls.getRiskControls({ token });
            return response?.data || [];
        } catch (error) {
            console.error("Error fetching control data:", error);
            return [];
        }
    };

    const fetchDummyData = async (): Promise<IControlData[]> => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(controlDummyData.data), 100);
        });
    };

    return { fetchData, fetchDummyData };
};

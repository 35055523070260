import { TableFiltersConfig } from "@epam/uui-core";
import {
    Button,
    FiltersPanel,
    FlexRow,
    FlexCell,
    SearchInput,
    FiltersPanelProps,
} from "@epam/uui";

import css from "./DataTableFilterPanel.module.scss";

export interface IDataTableFilterPanelProps<TFilter>
    extends FiltersPanelProps<TFilter> {
    resetHandler: () => void;
    searchHandler: (val: string | undefined) => void;
    importHandler?: () => void;
    exportHandler?: () => void;
}
export function DataTableFilterPanel<T>({
    filters,
    tableState,
    setTableState,
    resetHandler,
    searchHandler,
    importHandler,
    exportHandler,
}: IDataTableFilterPanelProps<T>) {
    return (
        <>
            <FlexRow cx={css.filterPanelWrapper} borderBottom={true}>
                <FlexRow cx={css.filterPanel}>
                    <FiltersPanel
                        filters={filters as TableFiltersConfig<object>[]}
                        tableState={tableState}
                        setTableState={setTableState}
                    />
                    <Button
                        caption="Reset"
                        onClick={() => {
                            resetHandler?.();
                        }}
                        color="primary"
                    />
                    <Button
                        caption="Import"
                        onClick={() => {
                            importHandler?.();
                        }}
                        color="accent"
                    />
                    <Button
                        caption="Export"
                        onClick={() => {
                            exportHandler?.();
                        }}
                        color="accent"
                    />
                </FlexRow>
                <FlexCell cx={css.search} width={295}>
                    <SearchInput
                        value={tableState.search}
                        onValueChange={searchHandler}
                        placeholder="Suchen"
                        debounceDelay={1000}
                    />
                </FlexCell>
            </FlexRow>
        </>
    );
}

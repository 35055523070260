import {
    ArrayDataSource,
    AsyncDataSource,
    TableFiltersConfig,
} from "@epam/uui-core";
import { defaultPredicates, FlexRow, Switch } from "@epam/uui";

import { IDocumentData } from "../data/api";

export const getFilterConfiguration = (
    data: IDocumentData[],
): TableFiltersConfig<IDocumentData>[] => {
    return [
        {
            field: "category",
            columnKey: "category",
            title: "Kategorie",
            type: "multiPicker",
            dataSource: new AsyncDataSource({
                api: () => {
                    let uniqueCategory = Array.from(
                        new Set(data.map((item) => item.category)),
                    );
                    return Promise.resolve(
                        uniqueCategory
                            .filter((i) => i !== "")
                            .map((item) => ({
                                id: item,
                                name: item,
                            })),
                    );
                },
            }),
            predicates: defaultPredicates.multiPicker,
            showSearch: true,
            highlightSearchMatches: true,
            isAlwaysVisible: true,
        },
    ];
};

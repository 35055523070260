import React, { useMemo } from "react";
import { IHasChildren } from "@epam/uui-core";
import {
    AdaptiveItemProps,
    Anchor,
    FlexCell,
    MainMenuCustomElement,
} from "@epam/uui-components";
import {
    Burger,
    BurgerButton,
    Dropdown,
    DropdownMenuBody,
    DropdownMenuButton,
    FlexSpacer,
    GlobalMenu,
    MainMenu,
    DropdownMenuSplitter,
    MainMenuAvatar,
    MainMenuButton,
    Text,
} from "@epam/uui";
import { useAppUser } from "../helpers/appUser";
import css from "./AppHeader.module.scss";

export interface AppHeaderProps extends IHasChildren {
    showPageMenu?: boolean;
    showGlobalMenu?: boolean;
}

export function AppHeader({ showPageMenu, showGlobalMenu }: AppHeaderProps) {
    const userContext = useAppUser();

    const logoUrl = useMemo(
        () =>
            userContext?.hasUser()
                ? `/static/logo_1.png`
                : `/static/logo-b2.png`,
        [userContext],
    );

    const avatarUrl = `https://api.dicebear.com/9.x/initials/svg?seed=FL&radius=50`;

    const renderAvatar = () => {
        if (!userContext?.hasUser()) return <></>;

        return (
            <Dropdown
                key="avatar"
                renderTarget={(props) => (
                    <MainMenuAvatar
                        avatarUrl={avatarUrl}
                        isDropdown
                        {...props}
                    />
                )}
                renderBody={(props) => (
                    <DropdownMenuBody {...props}>
                        <DropdownMenuButton caption="Settings" />
                        <DropdownMenuSplitter />
                        <DropdownMenuButton
                            caption="Log out"
                            onClick={() => {
                                userContext?.removeUser();
                            }}
                        />
                    </DropdownMenuBody>
                )}
                placement="bottom-end"
            />
        );
    };

    const getMainMenuItems = (): AdaptiveItemProps[] => {
        let items = [
            {
                id: "burger",
                priority: 100500,
                collapsedContainer: true,
                render: () => (
                    <Burger
                        renderBurgerContent={() => (
                            <BurgerButton
                                caption="Home"
                                link={{ pathname: "/" }}
                            />
                        )}
                        logoUrl={logoUrl}
                        key="burger"
                    />
                ),
            },
            {
                id: "logo",
                priority: 100499,
                render: () => (
                    <MainMenuCustomElement key="logo">
                        <Anchor link={{ pathname: "/" }}>
                            <img
                                src={logoUrl}
                                alt="IKSOnline"
                                style={{ marginLeft: "10px", marginTop: "5px" }}
                            />
                        </Anchor>
                    </MainMenuCustomElement>
                ),
            },
        ];

        if (userContext?.hasUser() && showPageMenu) {
            items.push(
                {
                    id: "risk",
                    priority: 2,
                    render: () => (
                        <MainMenuButton
                            caption="SR"
                            link={{ pathname: "/risk" }}
                            cx={[css.menuButton, css.default]}
                            showInBurgerMenu={true}
                            key="risk"
                        />
                    ),
                },
                {
                    id: "control",
                    priority: 3,
                    render: () => (
                        <MainMenuButton
                            caption="SK"
                            link={{ pathname: "/control" }}
                            cx={[css.menuButton, css.green]}
                            showInBurgerMenu
                            key="control"
                        />
                    ),
                },
                {
                    id: "document",
                    priority: 4,
                    render: () => (
                        <MainMenuButton
                            caption="RE"
                            link={{ pathname: "/document" }}
                            cx={[css.menuButton, css.purple]}
                            showInBurgerMenu
                            key="document"
                        />
                    ),
                },
                {
                    id: "user",
                    priority: 5,
                    render: () => (
                        <MainMenuButton
                            caption="VP"
                            link={{ pathname: "/user" }}
                            cx={[css.menuButton, css.blue]}
                            showInBurgerMenu
                            key="user"
                        />
                    ),
                },
                {
                    id: "report",
                    priority: 6,
                    render: () => (
                        <MainMenuButton
                            caption="AW"
                            link={{ pathname: "/report" }}
                            cx={[css.menuButton, css.default]}
                            showInBurgerMenu
                            key="report"
                        />
                    ),
                },
                {
                    id: "actualcontrol",
                    priority: 7,
                    render: () => (
                        <MainMenuButton
                            caption="DS"
                            link={{ pathname: "/ds" }}
                            cx={[css.menuButton, css.default]}
                            showInBurgerMenu
                            key="actualcontrol"
                        />
                    ),
                },
            );
        }

        items.push({
            id: "flexSpacer",
            priority: 100500,
            render: () => <FlexSpacer priority={100500} key="spacer" />,
        });

        items.push({
            id: "avatar",
            priority: 2,
            render: renderAvatar,
        });

        if (showGlobalMenu) {
            items.push({
                id: "globalMenu",
                priority: 100500,
                render: () => <GlobalMenu key="globalMenu" />,
            });
        }

        return items;
    };

    return (
        <FlexCell grow={1}>
            <MainMenu
                cx={css.root}
                items={getMainMenuItems()}
                serverBadge="DEMO"
            />
        </FlexCell>
    );
}

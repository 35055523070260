import { Badge, FlexRow, Text } from "@epam/uui";
import { IconContainer } from "@epam/uui-components";
import { SidebarDataItemConfig } from "src/common/SidebarDataPanel";

import { ReactComponent as IsImporantIcon } from "@epam/assets/icons/action-key-fill.svg";

import { IDocumentData } from "../data/api";

export const getDataConfiguration: SidebarDataItemConfig<IDocumentData>[] = [
    {
        title: "Nr.",
        render: (item) => {
            return <Text>{item.number}</Text>;
        },
    },
    {
        title: "Kategorie",
        render: (item) => {
            return <Text>{item.category}</Text>;
        },
    },
    {
        title: "Bezeichnung Dokument",
        render: (item) => {
            return <Text>{item.name}</Text>;
        },
    },
    {
        title: "Beschreibung Dokument",
        render: (item) => {
            return <Text>{item.description}</Text>;
        },
    },
    {
        title: "Gültig ab..",
        render: (item) => {
            return <Text>{item.effectiveDate?.toDateString()}</Text>;
        },
    },
    {
        title: "Datei/en",
        render: (item) => {
            return <Text>{item.file.name}</Text>;
        },
    },
    {
        title: "Verantwortlich",
        render: (item) => {
            return <Text>{item.responsibleUser.abbreviation}</Text>;
        },
    },
];

import { DataSourceState, TableFiltersConfig } from "@epam/uui-core";
import { IControlData } from "../data/api";

export const applyFilters = (
    data: IControlData[],
    filtersConfig: TableFiltersConfig<IControlData>[],
    state?: DataSourceState,
): IControlData[] => {
    let filteredData = data;

    Object.keys(state?.filter || {}).forEach((filterField) => {
        const filterValue = state?.filter?.[filterField];

        switch (filterField) {
            case "isActive":
                filteredData = filteredData.filter((item) => {
                    console.log("Filter value: ", filterValue);
                    if (filterValue === true) {
                        return item.isActive === true;
                    } else if (filterValue === false) {
                        return item.isActive === false;
                    }
                    return true;
                });

                console.log("Filtered data: ", filteredData);
                break;

            case "number":
                // "in" or "nin" filter for number
                if (filterValue?.in) {
                    filteredData = filteredData.filter((item) =>
                        filterValue.in.includes(item.number),
                    );
                } else if (filterValue?.nin) {
                    filteredData = filteredData.filter(
                        (item) => !filterValue.nin.includes(item.number),
                    );
                }
                break;

            case "risk":
                // "in" or "nin" filter for number
                if (filterValue?.in) {
                    filteredData = filteredData.filter((item) =>
                        filterValue.in.includes(item.risk.number),
                    );
                } else if (filterValue?.nin) {
                    filteredData = filteredData.filter(
                        (item) => !filterValue.nin.includes(item.risk.number),
                    );
                }
                break;

            case "isImportant": {
                if (Array.isArray(filterValue)) {
                    filteredData = filteredData.filter((item) => {
                        // Map 1 to true and 2 to false
                        const booleanValues = filterValue.map(
                            (val) => val === 1,
                        );
                        return booleanValues.includes(
                            item.isImportant ?? false,
                        );
                    });
                }
                break;
            }

            case "periodicity":
                if (filterValue?.in) {
                    filteredData = filteredData.filter((item) =>
                        filterValue.in.includes(item.periodicity.id),
                    );
                }
                break;

            case "responsibleUser":
                if (filterValue?.in) {
                    filteredData = filteredData.filter((item) =>
                        filterValue.in.includes(
                            item.responsibleUser.abbreviation,
                        ),
                    );
                } else if (filterValue?.nin) {
                    filteredData = filteredData.filter(
                        (item) =>
                            !filterValue.nin.includes(
                                item.responsibleUser.abbreviation,
                            ),
                    );
                }
                break;

            case "assignedUser1":
                if (filterValue?.in) {
                    filteredData = filteredData.filter((item) =>
                        filterValue.in.includes(
                            item.assignedUser1.abbreviation,
                        ),
                    );
                } else if (filterValue?.nin) {
                    filteredData = filteredData.filter(
                        (item) =>
                            !filterValue.nin.includes(
                                item.assignedUser1.abbreviation,
                            ),
                    );
                }
                break;

            default:
                break;
        }
    });

    return filteredData;
};

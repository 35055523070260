import { useState } from "react";
import {
    Button,
    FlexSpacer,
    ModalBlocker,
    ModalFooter,
    ModalHeader,
    ModalWindow,
    Panel,
    ScrollBars,
} from "@epam/uui";
import { IModal } from "@epam/uui-core";
import { RiskForm } from "./form";
import { IRiskData } from "../data/api";

import css from "./RiskModal.module.scss";

export interface IRiskModalProps {
    modalProps: IModal<IRiskData>;
    title: string;
    data: IRiskData;
    isVisible: boolean;
}

export const RiskModal = (props: IRiskModalProps) => {
    const { modalProps, title, data, isVisible } = props;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <ModalBlocker
            {...modalProps}
            cx={css.modalBlocker}
            disallowClickOutside={true}
        >
            <ModalWindow>
                <ModalHeader borderBottom title={title} />
                <ScrollBars hasTopShadow hasBottomShadow>
                    <RiskForm
                        risk={data}
                        onSave={() => {}}
                        onClose={() => {}}
                    />
                    <ModalFooter borderTop cx={css.footer}>
                        <Button
                            color="secondary"
                            fill="outline"
                            caption="Cancel"
                        />
                        <Button color="primary" caption="Confirm" />
                    </ModalFooter>
                    <FlexSpacer />
                </ScrollBars>
            </ModalWindow>
        </ModalBlocker>
    );
};

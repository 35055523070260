import { ILens } from "@epam/uui-core";
import { FlexCell, FlexRow, LabeledInput, TextArea } from "@epam/uui";
import { IRiskNetAssessmentData } from "../../data/api";
import { RiskAssessmentForm } from "./RiskAssessmentForm";

export interface RiskNetAssessmentFormProps {
    lens: ILens<IRiskNetAssessmentData>;
}
export const RiskNetAssessmentForm = (props: RiskNetAssessmentFormProps) => {
    const { lens } = props;

    return (
        <>
            {<RiskAssessmentForm lens={lens} type="netAssessment" />}
            <FlexRow>
                <FlexCell grow={1}>
                    <LabeledInput
                        htmlFor="initiatedOrPlannedMeasures"
                        label="Initiiert / Geplante Massnahmen / Personen / Zeitplan"
                        {...lens.prop("initiatedOrPlannedMeasures").toProps()}
                    >
                        <TextArea
                            {...lens
                                .prop("initiatedOrPlannedMeasures")
                                .toProps()}
                            placeholder=""
                            rows={6}
                            id="initiatedOrPlannedMeasures"
                        />
                    </LabeledInput>
                </FlexCell>
            </FlexRow>
        </>
    );
};

import { useState } from "react";
import { ILens, useUuiContext, UuiContexts } from "@epam/uui-core";
import {
    FlexRow,
    FlexCell,
    Panel,
    useForm,
    FlexSpacer,
    Button,
} from "@epam/uui";
import { useNotifications } from "../../hooks";
import { IRiskData } from "../../data/api";
import { RiskDetailsForm } from "./RiskDetailsForm";
import { RiskGrossAssessmentForm } from "./RiskGrossAssessmentForm";
import { RiskNetAssessmentForm } from "./RiskNetAssessmentForm";
import { RiskFormValidationSchema } from "./RiskFormValidationSchema";
import css from "./RiskForm.module.scss";

export interface IRiskFormProps {
    risk: IRiskData;
    onSave: (risk: IRiskData) => void;
    onClose: () => void;
}
export const RiskForm = (props: IRiskFormProps) => {
    const { risk, onSave, onClose } = props;
    const { showSuccess, showWarning, showError } = useNotifications();

    const { lens, save, validate } = useForm<IRiskData>({
        value: risk,
        onSave: async (risk: IRiskData) => {
            return onSave(risk);
        },
        onSuccess: () => {
            showSuccess("Data saved successfully");
        },
        onError: () => {
            showError("Failed to save the data");
        },
        getMetadata: RiskFormValidationSchema,
    });

    return (
        <div className={css.root}>
            <Panel background="surface-main" cx={css.formPanel} shadow={false}>
                <FlexCell width="100%">
                    <RiskDetailsForm lens={lens} />
                    <RiskGrossAssessmentForm
                        lens={lens.prop("grossAssessment")}
                    />
                    <RiskNetAssessmentForm lens={lens.prop("netAssessment")} />
                </FlexCell>
                {/* <FlexRow vPadding="12">
                    <FlexSpacer />
                    <Button caption="Save" onClick={save} color="primary" />
                </FlexRow> */}
            </Panel>
        </div>
    );
};

import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { TApi } from "../data/apiDefinition";
import { IDocumentData, documentDummyData } from "../data/api";

export const useDocumentData = () => {
    const svc = useUuiContext<TApi, UuiContexts>();

    const fetchData = async (token: string) => {
        if (!token) return [];
        try {
            const response = await svc.api.documents.getRegulatoryDocuments({
                token,
            });
            return response?.data || [];
        } catch (error) {
            console.error("Error fetching document data:", error);
            return [];
        }
    };

    const fetchDummyData = async (): Promise<IDocumentData[]> => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(documentDummyData.data), 100);
        });
    };

    return { fetchData, fetchDummyData };
};

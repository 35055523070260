import { IIksApiResponse } from "../baseTypes";
import { IUserData } from "./userTypes";

export const userDummyData: IIksApiResponse<IUserData[]> = {
    status: "Success",
    message: "Operation completed successfully",
    data: [
        {
            __typename: "User",
            id: "1a2b3c4d-0001-0000-0000-123456789abc",
            fullName: "Alice Kaufmann",
            abbreviation: "AKF",
            functionName: "Risk Manager",
            departmentName: "Risk Management",
            sectorName: "Finance",
            email: "alice.kaufmann@example.com",
            isActive: true,
        },
        {
            __typename: "User",
            id: "1a2b3c4d-0002-0000-0000-123456789abc",
            fullName: "Michael Schmid",
            abbreviation: "MSC",
            functionName: "Compliance Officer",
            departmentName: "Compliance",
            sectorName: "Legal",
            email: "michael.schmid@example.com",
            isActive: true,
        },
        {
            __typename: "User",
            id: "1a2b3c4d-0003-0000-0000-123456789abc",
            fullName: "Sophie Müller",
            abbreviation: "SMU",
            functionName: "Audit Specialist",
            departmentName: "Internal Audit",
            sectorName: "Corporate Governance",
            email: "sophie.mueller@example.com",
            isActive: true,
        },
        {
            __typename: "User",
            id: "1a2b3c4d-0004-0000-0000-123456789abc",
            fullName: "Daniel Weber",
            abbreviation: "DWB",
            functionName: "IT Security Analyst",
            departmentName: "IT Security",
            sectorName: "Technology",
            email: "daniel.weber@example.com",
            isActive: true,
        },
        {
            __typename: "User",
            id: "1a2b3c4d-0005-0000-0000-123456789abc",
            fullName: "Julia Meier",
            abbreviation: "JME",
            functionName: "Operations Manager",
            departmentName: "Operations",
            sectorName: "Business",
            email: "julia.meier@example.com",
            isActive: true,
        },
        {
            __typename: "User",
            id: "1a2b3c4d-0006-0000-0000-123456789abc",
            fullName: "Thomas Berger",
            abbreviation: "TBE",
            functionName: "Finance Analyst",
            departmentName: "Finance",
            sectorName: "Accounting",
            email: "thomas.berger@example.com",
            isActive: false,
        },
        {
            __typename: "User",
            id: "1a2b3c4d-0007-0000-0000-123456789abc",
            fullName: "Laura Fischer",
            abbreviation: "LFI",
            functionName: "HR Business Partner",
            departmentName: "Human Resources",
            sectorName: "People & Culture",
            email: "laura.fischer@example.com",
            isActive: false,
        },
    ],
};

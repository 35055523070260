import { IProcessRequest } from "@epam/uui-core";
import { IControlData, IControlRequest } from "./controlTypes";
import { IIksApiResponse } from "../baseTypes";

export function getControlsApi(
    processRequest: IProcessRequest,
    origin: string,
) {
    return {
        getRiskControls(req: IControlRequest) {
            const { token } = req;
            return processRequest<IIksApiResponse<IControlData[]>>(
                `${origin}/api/v1/controls`,
                "GET",
                null,
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
            );
        },
    };
}
